<template>
  <v-timeline id="rowlo-poem-timeline-wide" class="ma-0 pa-0" align-top>
    <v-timeline-item
      v-for="(poem, i) in poems"
      :key="i"
      :color="poem.color"
      fill-dot
    >
      <template v-slot:icon>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on"></v-avatar>
          </template>
          <span v-if="!poem.image">{{ poem.language }}</span>
          <span v-else>{{ poem.title }}</span>
        </v-tooltip>
      </template>
      <template v-slot:opposite v-if="!poem.image">
        <span
          class="signature-text"
          :style="`color: ${poem.color};`"
          v-for="(sig, s) in poem.signature"
          :key="s"
          >{{ sig }}<br
        /></span>
      </template>

      <rowlo-poem-card
        :poem="poem"
        :startWithFullText="showFullText(poem.id)"
      ></rowlo-poem-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import RowloPoemCard from '@/components/rowlo-poem-card'

export default {
  name: 'rowlo-poem-timeline-wide',
  components: {
    RowloPoemCard,
  },
  props: ['poems', 'showFullText'],
}
</script>

<style scoped>
.signature-text {
  font-family: 'Arizonia', cursive;
  font-size: 26px;
}
</style>
