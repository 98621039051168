<template>
  <v-row id="rowlo-poems" class="ma-0 pa-0">
    <rowlo-title :title="computeTitle"></rowlo-title>

    <rowlo-circular-progress v-if="isLoading"></rowlo-circular-progress>
    <v-col
      v-else
      xl="6"
      offset-xl="3"
      lg="8"
      offset-lg="2"
      sm="10"
      offset-sm="1"
      cols="12"
      offset="0"
      class="pa-0"
    >
      <rowlo-poem-timeline-wide
        v-if="$breakpoint.mdAndUp"
        :poems="poems"
        :showFullText="showFullText"
      ></rowlo-poem-timeline-wide>
      <rowlo-poem-timeline-small
        v-if="$breakpoint.smAndDown"
        :poems="poems"
        :showFullText="showFullText"
      ></rowlo-poem-timeline-small>
    </v-col>
  </v-row>
</template>

<script>
import { eventBus } from '@/main.js'
import apiMixin from '@/mixins/api-mixin.js'
import breakpoint from 'vue-md-breakpoint'
import RowloPoemTimelineWide from '@/components/rowlo-poem-timeline-wide'
import RowloPoemTimelineSmall from '@/components/rowlo-poem-timeline-small'
import RowloCircularProgress from '@/components/rowlo-circular-progress'

export default {
  name: 'rowlo-poems',
  mixins: [apiMixin, breakpoint],
  components: {
    'rowlo-poem-timeline-wide': RowloPoemTimelineWide,
    'rowlo-poem-timeline-small': RowloPoemTimelineSmall,
    'rowlo-circular-progress': RowloCircularProgress,
  },
  data: () => ({
    poems: [],
    fullTextPoems: [],
  }),
  computed: {
    computeTitle() {
      return this.$t('siteTitle') + this.$t('siteSubtitleGoWrite')
    },
  },
  methods: {
    processPoemsSummary(poemsSummary) {
      eventBus.clearPoems()
      eventBus.awaitPoems(poemsSummary.length)
      poemsSummary.forEach(x => {
        eventBus.addPoemSummary(x)
      })
    },
    fetchData() {
      this.apiGet('poems/poemssummary', this.processPoemsSummary)
    },
    showFullText(poemId) {
      return this.fullTextPoems.includes(poemId)
    },
    acquirePoems() {
      let poems = eventBus.$data.poems
      this.poems = poems.sort((a, b) => {
        if (a.id === b.id && b.image) {
          return -1
        }
        return b.id - a.id
      }) // sort recent to oldest
    },
    clearSearchFilter() {
      this.acquirePoems()
      this.updateFullTextPoems()
    },
    updateFullTextPoems() {
      this.fullTextPoems =
        this.poems.length < 3 ? this.poems.map(a => a.id) : []
    },
    filterPoems(searchPattern) {
      this.poems = this.poems.filter(poem => {
        return this.patternMatchesPoem(poem, searchPattern)
      })
      this.updateFullTextPoems()
    },
    patternMatchesId(poem, pattern) {
      return poem.id.toString() === pattern
    },
    patternMatchesTitle(poem, pattern) {
      if (poem.title) {
        return poem.title.toLowerCase().indexOf(pattern) >= 0
      }
      return false
    },
    patternMatchesVerses(poem, pattern) {
      if (poem.verses) {
        let matchingVerses = poem.verses.filter(
          verse =>
            verse.lines
              .join(' ')
              .toLowerCase()
              .indexOf(pattern) >= 0
        )
        return matchingVerses.length > 0
      }
      return false
    },
    patternMatchesSignature(poem, pattern) {
      if (poem.signature) {
        return (
          poem.signature
            .join(' ')
            .toLowerCase()
            .indexOf(pattern) >= 0
        )
      }
      return false
    },
    patternMatchesLanguage(poem, pattern) {
      if (poem.language) {
        return poem.language.toLowerCase().indexOf(pattern) >= 0
      }
      return false
    },
    patternMatchesLoevingerStage(poem, pattern) {
      if (poem.loevingerStage) {
        return poem.loevingerStage.toLowerCase().indexOf(pattern) >= 0
      }
      return false
    },
    patternMatchesPoem(poem, pattern) {
      let matchesId = this.patternMatchesId(poem, pattern)
      let matchesTitle = this.patternMatchesTitle(poem, pattern)
      let matchesVerses = this.patternMatchesVerses(poem, pattern)
      let matchesSignature = this.patternMatchesSignature(poem, pattern)
      let matchesLanguage = this.patternMatchesLanguage(poem, pattern)
      let matchesLoevingerStage = this.patternMatchesLoevingerStage(
        poem,
        pattern
      )
      return (
        matchesId ||
        matchesTitle ||
        matchesVerses ||
        matchesSignature ||
        matchesLanguage ||
        matchesLoevingerStage
      )
    },
    listenOnPoemWasAdded() {
      eventBus.$on('poemWasAdded', () => {
        this.acquirePoems()
        eventBus.updateSearchFromRoute(this.$route.query)
      })
    },
    listenOnUpdateSearchPattern() {
      eventBus.$on('updateSearchPattern', searchPattern => {
        this.clearSearchFilter()
        if (searchPattern !== null && searchPattern.trim() !== '') {
          this.filterPoems(searchPattern)
        }
      })
    },
  },
  created() {
    eventBus.updateI18nFromRoute(this.$route.query)
    this.acquirePoems()
    this.listenOnPoemWasAdded()
    this.listenOnUpdateSearchPattern()
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style scoped>
.signature-text {
  font-family: 'Arizonia', cursive;
  font-size: 26px;
}
</style>
